<template>
  <div v-if="volume">
    <template v-if="volume.status !== 'published'">
      <closed-message :volume="volume" />
    </template>

    <template v-else>
      <hero :volume="volume" />

      <div class="container pb-5 px-5" style="max-width: 700px;" v-if="categoryContents">
        <div class="category" v-for="(contents, category) in categoryContents" :key="category.id">
          <p class="title is-5">{{ category }}</p>
          <div class="content-list">
            <ul>
              <li v-for="content in contents" :key="content.id">
                <router-link :to="{name: 'content', params: {contentId: content.id}}">
                  <div class="columns is-mobile is-gapless">
                    <div class="column is-narrow">
                      <fa-icon :icon="['fas', 'play-circle']" class="mr-2" />    
                    </div>
                    <div class="column">{{ content.title }}</div>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="mb-6">
          <router-link :to="{ name: 'survey', params: {volumeId: volume.id} }" class="survey-button">
            <span>みなさまのご感想を</span><span class="">お聞かせください🙇‍♂️</span>
          </router-link>
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import { db } from '@/main'
import Hero from '@/components/Hero'
import ClosedMessage from '@/components/ClosedMessage'
import { groupBy } from '@/utils/array'

export default {
  components: { Hero, ClosedMessage },
  data() {
    return {
      volumeId: this.$route.params.volumeId,
      volume: null,
      categories: null,
      contents: null,
    }
  },
  firestore() {
    const volumeRef = db.collection('volumes').doc(this.volumeId)

    return {
      volume: volumeRef,
      categories: volumeRef.collection('categories').orderBy('position', 'asc'),
      contents: volumeRef.collection('contents').orderBy('position', 'asc'),
    }
  },
  computed: {
    categoryContents: function() {
      return groupBy(this.contents, c => c.categoryRef.title || '')
    }
  }
}
</script>


<style lang="scss">
  .category {
    margin-bottom: 4rem;
  }

  .content-list {
    box-shadow: rgba(60, 64, 67, 0.15) 0px 1px 2px 0px, rgba(60, 64, 67, 0.1) 0px 2px 6px 2px;
    border-radius: 3px;
    padding: 0.75rem;

    ul li {
      a {
        border-radius: 2px;
        color: #555;
        display: inline-block;
        width: 100%;
        padding: 0.75rem 1rem;

        &:hover {
          background-color: whitesmoke;
          text-decoration: underline;
        }
      }
    }
  }

  .survey-button {
    width: 100%;
    height: 5rem;
    font-size: 1.25rem;
    background-color: #1e5fbb;
    color: #fff;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    padding: 1rem 0.5rem;

    &:hover {
      color: #fff;
    }

    span {
      line-height: 1;
    }
  }
</style>
